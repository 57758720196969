import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { addPriceData } from '@/store/modules/refund';
import SupplyInputItem from './SupplyInputItem';
import { IOrderInfoList } from '@/types/refund';

type Props = {
  isPorteKor?: boolean;
};

function SupplyInput({ isPorteKor = false }: Props) {
  const { temporaryStorage } = useAppSelector((state) => state.refund);
  const methods = useFormContext();
  const dispatch = useAppDispatch();
  const intialCard = {
    productType: '',
    price: '',
    supplyPrice: '',
    vatPrice: '',
  };

  const { fields, append, remove } = useFieldArray({
    control: { ...methods.control },
    name: 'supply',
  });

  // 임시저장 관련코드
  useEffect(() => {
    if (!temporaryStorage.supplyDate) return;
    const currSupplyLength = methods.getValues('supply').length;
    const storageSupplyLength = temporaryStorage.supply.length;
    if (storageSupplyLength > currSupplyLength) {
      for (let i = currSupplyLength; i < storageSupplyLength; i++) {
        onAdd();
      }
    }
    if (storageSupplyLength < currSupplyLength) {
      storageRmove(currSupplyLength, storageSupplyLength);
    }
  }, [temporaryStorage]);

  const storageRmove = async (
    currSupplyLength: number,
    storageSupplyLength: number,
  ) => {
    const removeArray: number[] = [];
    let value = 0;
    for (let i = currSupplyLength - 1; i >= storageSupplyLength; i--) {
      if (currSupplyLength - value > 3) {
        removeArray.push(i);
        value++;
      }
    }
    remove(removeArray);
  };

  const onAdd = () => {
    append({
      productType: '',
      price: '',
      supplyPrice: '',
      vatPrice: '',
      isCheck: false,
    });
    dispatch(addPriceData(intialCard));
  };
  // 선택 삭제
  const onRemove = async () => {
    const supplyList = methods.getValues().supply;
    const removeArray: number[] = [];

    await supplyList.forEach((item: IOrderInfoList, index: number) => {
      if (item.isCheck) {
        removeArray.push(index);
      }
    });

    removeArray.forEach((item) => {
      methods.setValue(`supply.${item}.price`, '');
      methods.setValue(`supply.${item}.productType`, '');
      methods.setValue(`supply.${item}.supplyPrice`, '');
      methods.setValue(`supply.${item}.vatPrice`, '');
    });
    remove(removeArray.reverse());
  };

  const MAX_FIELDS = 9;
  const isDisabledButton = useMemo(() => fields.length >= MAX_FIELDS, [fields]);

  return (
    <>
      <InputList>
        {fields.map((item, index) => (
          <SupplyInputItem
            key={item.id}
            index={index}
            onRemove={onRemove}
            isPorteKor={isPorteKor}
          />
        ))}
      </InputList>
      <AddButton onClick={onAdd} disabled={isDisabledButton} id='no_focus'>
        <AddButtonText>+ 시술 추가</AddButtonText>
      </AddButton>
    </>
  );
}
const InputList = styled.ul``;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 12px 0;
  color: ${(props) => props.theme.blue[100]};
  &:disabled {
    color: #d3e2fd;
  }
`;

const AddButtonText = styled.span`
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
`;

export default SupplyInput;
