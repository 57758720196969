import { createGlobalStyle, DefaultTheme } from 'styled-components';
import reset from 'styled-reset';

export const theme: DefaultTheme = {
	fontSize: {
		xLarge: '30px',
		large: '24px',
		medium: '20px',
		regular: '18px',
		small: '16px',
		xSmall: '14px',
	},
	fontWeight: {
		bold: 700,
		semiBold: 600,
		medium: 500,
		regular: 400,
	},
	lineHeight: {
		xLarge: '38px',
		large: '32px',
		medium: '28px',
		regular: '26px',
		small: '24px',
		xSmall: '22px',
	},
	priamryColors: {
		primary: '#00658C',
		primaryVarient: '#E2F3FF',
		error: '#E42939',
	},
	pointColors: {
		white: '#FFFFFF',
	},
	grayColors: {
		scale1: '#212121',
		scale2: '#757575',
		scale3: '#BDBDBD',
		scale4: '#E0E0E0',
		scale5: '#F5F5F5',
	},
	blue: {
		5: '#F4F8FF',
		20: '#D3E2FD',
		60: '#7CA7FA',
		80: '#5089F8',
		100: '#246CF6',
		150: '#1856CD',
		200: '#0B42AD',
	},
	mono: {
		5: '#F5F6F7',
		10: '#E5E6E8',
		20: '#CBCCCE',
		35: '#A7A7A7',
		50: '#80848A',
		65: '#5F6165',
		60: '#5F6165',
		80: '#3A3B3E',
	},
	blueColors: {
		scale1: '#DADEE4',
		scale2: '#EAEFF3',
		scale3: '#F7F9FC',
		scale4: '#A9C8DD',
	},
	boxShadow: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.14))',
	laptop: '(max-width: 1600px)',
	smallView: '(min-width: 1280px)',
	mediumView: '(min-width: 1510px)',
	largeView: '(min-width: 1920px)',
};

export const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
      box-sizing: border-box;
      font-family: 'Noto Scans KR';
      font-weight: ${(props) => props.theme.fontWeight.regular};
      line-height: ${(props) => props.theme.lineHeight.xSmall};
      font-size: ${(props) => props.theme.fontSize.xSmall};
      :focus{
        outline: 0;
      }
  }

  body {
    font-family: 'Noto Scans KR';
      font-weight: ${(props) => props.theme.fontWeight.regular};
      line-height: ${(props) => props.theme.lineHeight.xSmall};
      font-size: ${(props) => props.theme.fontSize.xSmall};
      min-width: 1024px;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.grayColors.scale1};
  }

  img {
    object-fit: contain;
  }

  button {
    cursor: pointer;
    border:none;
    background-color:transparent;
    outline:none;
    :disabled {
      cursor: auto;
    }
  }

  input:-webkit-autofill {
	  -webkit-box-shadow: 0 0 0 1000px white inset;
    box-shadow: 0 0 0 1000px white inset;
  }
`;
