import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import logo from '@/assets/common/logo.png';

type navigationType = {
  isTestServer: boolean;
};

function Naviagtion({ isTestServer }: navigationType) {
  const navigate = useNavigate();
  return (
    <Container isTestServer={isTestServer}>
      <Logo onClick={() => navigate('/')}>
        <Image src={logo} alt='로고.' />
      </Logo>
    </Container>
  );
}

const Container = styled.nav<{ isTestServer: boolean }>`
  height: 80px;
  padding: 28px 32px;
  background-color: ${(props) =>
    props.isTestServer ? 'green' : props.theme.blue[150]};
  width: 200px;
  ${(props) => props.theme.largeView} {
    width: 240px;
  }
`;

const Logo = styled.div`
  cursor: pointer;
`;

const Image = styled.img`
  width: 108px;
  height: 34px;
`;

export default Naviagtion;
