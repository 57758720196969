import { format } from 'date-fns';
import React, { Dispatch, SetStateAction } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { NoticeHomeHeader } from '@/constants/refundGraph';
import { Notice } from '@/types/home';
import Button from '../common/Button';
import FlexWrap from '../common/FlexWrap';
import Paging from '../common/Paging';
import Text from '../common/Text';
import { newRegisterHospital } from '@/util/common';

interface IProps {
  totalPage?: number;
  setPageNumber?: Dispatch<SetStateAction<number>>;
  medicalNotice: Notice[];
  isNotice?: boolean;
  setIsNotice?: Dispatch<SetStateAction<boolean>>;
}

function HomeNotice({
  medicalNotice,
  isNotice,
  totalPage,
  setPageNumber,
}: IProps) {
  const navigate = useNavigate();

  return (
    <>
      <Container dir='column'>
        {!isNotice && (
          <TitleContainer justifyContent='space-between' alignItems='center'>
            <Title>공지사항</Title>
            <Button
              text='+ 더보기'
              color='#757575'
              fontWeight
              onClick={() => navigate('/home/notice')}
            />
          </TitleContainer>
        )}

        <Header>
          {NoticeHomeHeader.map((item) => (
            <HeaderTitle key={item.id} flex={item.flex}>
              {item.title}
            </HeaderTitle>
          ))}
        </Header>

        <NoticeList>
          {medicalNotice.map((item, index) => (
            <NoticeItem
              key={index}
              to={'/home/notice/detail'}
              state={{ isDetail: true, noticeIndex: item.noticeIndex }}
            >
              <Content flex={1} isRed={newRegisterHospital(item.scheduledDate)}>
                {newRegisterHospital(item.scheduledDate)
                  ? 'NEW'
                  : '0' + Number(index + 1)}
              </Content>
              <Content flex={12} isFlexStart>
                <Text text={item.title} />
              </Content>
              <Content flex={1.5}>
                {format(new Date(item.scheduledDate), 'yyyy-MM-dd')}
              </Content>
              <Content flex={1.5}>{item.writer}</Content>
            </NoticeItem>
          ))}
        </NoticeList>
      </Container>
      {totalPage && setPageNumber && (
        <Paging setPageNumber={setPageNumber} totalPage={totalPage} />
      )}
    </>
  );
}

const Container = styled(FlexWrap)`
  position: relative;
`;

const TitleContainer = styled(FlexWrap)`
  width: 100%;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
  margin-bottom: 16px;
  @media ${(props) => props.theme.largeView} {
    margin-bottom: 24px;
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.mono[5]};
  border-bottom: 1px solid ${(props) => props.theme.mono[20]};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const HeaderTitle = styled.p<{ flex?: number }>`
  padding: 12px 0;
  flex: ${(props) => props.flex};
  text-align: center;
  color: ${(props) => props.theme.mono[65]};
  font-size: ${(props) => props.theme.fontSize.xSmall};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  line-height: ${(props) => props.theme.lineHeight.xSmall};
`;

const NoticeList = styled.ul`
  width: 100%;
`;

const NoticeItem = styled(Link)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.mono[10]};
  background-color: ${(props) => props.theme.pointColors.white};
  :last-child {
    border-bottom: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

const Content = styled.div<{
  flex: number;
  isFlexStart?: boolean;
  isRed?: boolean;
}>`
  display: flex;
  justify-content: ${(props) => (props.isFlexStart ? 'flex-start' : 'center')};
  align-items: center;
  width: 100%;
  height: 48px;
  flex: ${(props) => props.flex};
  font-weight: 500;
  padding: 12px 0;
  color: ${(props) => (props.isRed ? '#FF2D55' : '#3A3B3E')};
`;

export default HomeNotice;
