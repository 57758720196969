import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import Text from '../common/Text';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { passwordChange } from '@/api/user';

interface IProps {
  setIsPasswordChange: Dispatch<SetStateAction<boolean>>;
}

export interface IPasswordChange {
  newPassword: string;
}

function PasswordChange({ setIsPasswordChange }: IProps) {
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      newConfirmPassword: '',
    },
  });
  const { newPassword, newConfirmPassword } = watch();

  const { mutate: passwordChangeMutation } = useMutation<
    boolean,
    AxiosError<any>,
    IPasswordChange
  >((payload) => passwordChange(payload), {
    retry: false,
    onSuccess: async () => {
      setIsPasswordChange(false);
      alert('비밀번호 변경이 되었습니다.');
    },
    onError: (error: any) => {
      console.log('에러', error);
    },
  });

  const onSubmit = ({ newPassword }: IPasswordChange) => {
    const payload = {
      newPassword,
    };
    passwordChangeMutation(payload);
  };

  return (
    <Container>
      <Title>비밀번호 변경</Title>
      <Form>
        <InputContainer>
          <Text text='새 비밀번호' fontWeight='500' color='#3A3B3E' />
          <Input
            type='password'
            {...register('newPassword', {
              required: '필수 입력 사항입니다.',
            })}
            placeholder='새 비밀번호를 입력해주세요'
          />
          {errors.newPassword && (
            <ErrorText>{errors.newPassword.message!}</ErrorText>
          )}
        </InputContainer>
        <InputContainer>
          <Text text='새 비밀번호 확인' fontWeight='500' color='#3A3B3E' />
          <Input
            type='password'
            {...register('newConfirmPassword', {
              required: '필수 입력 사항입니다.',
              validate: {
                matchesPassword: (value) => {
                  const { newPassword } = getValues();
                  return (
                    newPassword === value || '비밀번호가 일치하지 않습니다.'
                  );
                },
              },
            })}
            placeholder='새 비밀번호를 확인해주세요'
          />
          {errors.newConfirmPassword && (
            <ErrorText>{errors.newConfirmPassword.message!}</ErrorText>
          )}
        </InputContainer>
      </Form>
      <ButtonWrapper>
        <Button isCancle onClick={() => setIsPasswordChange(false)}>
          취소
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={!Boolean(newPassword && newConfirmPassword)}
        >
          변경하기
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.p`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
  margin-top: 34px;
`;

const Form = styled.form`
  border-radius: 16px;
  width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 32px;
  gap: 16px;
  margin-top: 200px;
  background-color: ${(props) => props.theme.pointColors.white};
  @media ${(props) => props.theme.largeView} {
    gap: 24px;
  }
`;

const InputContainer = styled.div<{ address?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  position: relative;
  width: 100%;
  max-width: 600px;
`;

const Input = styled.input`
  padding: 13px 10px;
  border-radius: 4px;
  width: 480px;
  color: ${(props) => props.theme.grayColors.scale2};
  border: 2px solid ${(props) => props.theme.mono[20]};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  margin-top: 220px;
  width: 100%;
`;

const Button = styled.button<{ isCancle?: boolean }>`
  width: 240px;
  height: 48px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  border-radius: 8px;
  color: ${(props) =>
    props.isCancle ? props.theme.mono[80] : props.theme.pointColors.white};
  background-color: ${(props) =>
    props.isCancle ? props.theme.mono[10] : props.theme.blue[100]};
  :hover {
    opacity: 0.7;
  }
  :disabled {
    background-color: ${(props) => props.theme.blue[20]};
    opacity: 1;
  }
`;

const ErrorText = styled.p`
  position: absolute;
  bottom: -25px;
  left: 125px;
  color: ${(props) => props.theme.priamryColors.error};
`;

export default PasswordChange;
