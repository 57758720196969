import { useState } from 'react';
import { useQuery } from 'react-query';

import { getRefundStatement } from '@/api/refund';

const useStatement = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const { data, isSuccess, isLoading } = useQuery<any, null>(
    ['refundStatement'],
    async () => await getRefundStatement(),
    {
      onSuccess: (res) => {
        setTotalPage(res.totalPages);
        setTotalElements(res.totalElements);
      },
      retry: false,
    }
  );

  return {
    data,
    isSuccess,
    totalPage,
    totalElements,
    pageNumber,
    setPageNumber,
    isLoading,
  };
};

export default useStatement;
