import React, { Fragment } from 'react';
import styled from 'styled-components';

import { useAppSelector } from '@/hooks/useReduxHooks';

interface IProps {
  titleList: CardGraph[];
}

interface CardGraph {
  id: number;
  title: string;
  flex: number;
}

function TableHeader({ titleList }: IProps) {
  const { hospitals, isKioskStore } = useAppSelector((state) => state.user);

  return (
    <Container>
      {titleList.map((card) => (
        <Fragment key={card.id}>
          {hospitals.length === 0 && card.title === '구분' ? (
            <></>
          ) : !isKioskStore && card.title === '키오스크 환급액' ? (
            <></>
          ) : (
            <CardTitle
              flex={card.flex}
              isKioskStore={card.title === '키오스크 환급액'}
            >
              {card.title}
            </CardTitle>
          )}
        </Fragment>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 26px;
  background-color: ${(props) => props.theme.mono[10]};
  border-bottom: 1px solid ${(props) => props.theme.mono[35]};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const CardTitle = styled.h5<{ flex: number; isKioskStore: boolean }>`
  display: flex;
  flex: ${(props) => props.flex};
  justify-content: center;
  padding: 18px 8px;
  font-size: ${(props) => props.theme.fontSize.xSmall};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.xSmall};
  color: ${(props) =>
    props.isKioskStore ? props.theme.blue[200] : props.theme.mono[65]};
  @media ${(props) => props.theme.mediumView} {
    padding: 18px 20px;
  }
`;

export default TableHeader;
