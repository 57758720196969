import React, { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';

import FlexWrap from '../common/FlexWrap';
import Icon from '../common/Icon';

interface IProps {
  title: string;
  content: string | ReactNode;
  btnDir?: 'column' | 'row';
  btnText: string;
  subBtnText?: string;
  btnCallback?: () => void; //버튼 동작: 기본 동작(onClose)+btnCallback 실행
  subBtnCallback?: () => void; //버튼 동작: 기본 동작(onClose)+subBtnCallback 실행
  onClose: () => void;
  btnActive?: boolean;
  subBtnActive?: boolean;
  preventDefault?: boolean; //true: 버튼 기본동작 막기
  icon?: string;
}
function CustomModal({
  title,
  content,
  btnDir = 'row',
  btnText,
  subBtnText,
  btnCallback,
  subBtnCallback,
  onClose,
  icon,
  btnActive = true,
  subBtnActive = true,
  preventDefault = false,
}: IProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, []);

  const onClickBtnCallback = () => {
    if (btnCallback) {
      btnCallback();
      onClose();
    }
  };

  return (
    <Container>
      <Modal>
        <FlexWrap>
          {icon && (
            <Icon imgUrl={icon} width='28px' height='28px' margin='0 8px 0 0' />
          )}

          <ModalTitle>{title}</ModalTitle>
        </FlexWrap>
        <Description>{content}</Description>
        <BtnContainer dir={btnDir} gap='12px'>
          {subBtnText && (
            <Button
              onClick={onClickBtnCallback}
              active={subBtnActive}
              disabled={!subBtnActive}
            >
              {subBtnText}
            </Button>
          )}
          <Button
            ref={buttonRef}
            onClick={() => {
              !preventDefault && onClose();
              btnCallback && btnCallback();
            }}
            colored
            active={btnActive}
            disabled={!btnActive}
          >
            {btnText}
          </Button>
        </BtnContainer>
      </Modal>
    </Container>
  );
}
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Modal = styled(FlexWrap)`
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
  background-color: white;
  border-radius: 16px;
  min-width: 400px;
`;
const ModalTitle = styled.h3`
  font-size: 21px;
  line-height: 150%;
  font-weight: 700;
  color: #030303;
`;
const Description = styled.p`
  font-size: 14px;
  line-height: 150%;
  margin: 16px 0;
  text-align: center;
  font-weight: 500;
  white-space: pre-wrap;
  color: #5f6165;
`;
const BtnContainer = styled(FlexWrap)`
  width: 100%;
`;
const Button = styled.button<{ colored?: boolean; active: boolean }>`
  height: 52px;
  flex: 1;
  background-color: ${(props) =>
    props.active ? (props.colored ? '#246cf6' : '#E5E6E8') : '#D3E2FD'};
  color: ${(props) =>
    props.active ? (props.colored ? '#fff' : '#3A3B3E') : '#fff'};
  border: 1px solid #246cf6;
  border: 1px solid ${(props) => (props.active ? '#E5E6E8' : '#D3E2FD')};
  border-radius: 8px;
  font-size: 16px;
  line-height: 150%;
  padding: 14px 20px;
  :hover {
    opacity: 0.85;
  }
`;

export default CustomModal;
