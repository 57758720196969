import React from 'react';
import styled from 'styled-components';

import Text from '@/components/common/Text';
import cancleImage from '@/assets/common/cancle.png';

interface IProps {
	title: string;
	desc: string;
	contentElement?: React.ReactNode;
	onClickBtn: () => void;
	onClickSubBtn: () => void;
	btnTitle: string;
	subBtnTitle: string;
	onClose?: () => void;
}

function CustomModal({
	title,
	desc,
	onClickBtn,
	onClickSubBtn,
	btnTitle,
	subBtnTitle,
	onClose,
	contentElement,
}: IProps) {
	return (
		<Wrapper>
			<Container>
				<Header>
					<Text
						text={title}
						fontSize="24px"
						lineHeight="32px"
						fontWeight="700"
					/>
					{onClose && <Cancle src={cancleImage} onClick={onClose} />}
				</Header>
				<Desc>{desc}</Desc>
				{contentElement}
				<ButtonWrapper>
					<Button color="#3A3B3E" bgColor="#E5E6E8" onClick={onClickSubBtn}>
						{subBtnTitle}
					</Button>
					<Button color="#FFFFFF" bgColor="#246CF6" onClick={onClickBtn}>
						{btnTitle}
					</Button>
				</ButtonWrapper>
			</Container>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	z-index: 10;
	background-color: rgba(33, 33, 33, 0.1);
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 600px;
	padding: 36px 40px;
	position: relative;
	border-radius: 8px;
	background-color: ${(props) => props.theme.pointColors.white};
	filter: ${(props) => props.theme.boxShadow};
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;
const Desc = styled.p`
	font-size: ${(props) => props.theme.fontSize.xSmall};
	line-height: 150%;
	color: #5f6165;
	margin: 16px 0;
`;
const Button = styled.button<{ bgColor: string; color: string }>`
	flex: 1;
	border-radius: 8px;
	padding: 14px;
	font-size: ${(props) => props.theme.fontSize.small};
	color: ${(props) => props.color};
	background-color: ${(props) => props.bgColor};
	line-height: 150%;
	font-weight: 600;
`;
const Cancle = styled.img`
	top: 0;
	right: 0;
	width: 18px;
	height: 18px;
	cursor: pointer;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 18px;
	margin-top: 16px;
`;

export default CustomModal;
