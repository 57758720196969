import html2canvas from 'html2canvas';

export const convertComponentToImage = async (el: HTMLElement) => {
  const canvas = await html2canvas(el);
  return dataURItoBlob(canvas.toDataURL('image/png', 1.0));
};
export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};
export const formatDateYMD = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  return { year, month, day };
};
// 환급상태구분
// 24.07.10 송금중 상태 제거 (송금대기 상태 수정)
// else if (paymentStatus === 'PAYMENT_IN_PROGRESS') {
//   return '승인(송금중)';
// }
export const refundStatusDivision = (status: string) => {
  if (status === 'OK') return '승인';
  if (status === 'CANCEL') return '취소';
  if (status === 'DEPARTURE_CHECK') return '출국 확인중';
  if (status === 'TRANSFER_CHECK') return '송금 대기';
  return '-';
};
// 일주일전인지 확인
export const newRegisterHospital = (date: string) => {
  const currentDate = new Date();
  const weekAgoDate = new Date();
  const createdDate = new Date(date);

  weekAgoDate.setDate(currentDate.getDate() - 7);
  return weekAgoDate.getTime() < createdDate.getTime();
};
