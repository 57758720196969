import styled from 'styled-components';
import HomeContent from '@/components/home/HomeContent';
import Footer from '@/components/common/Footer';
import WarningExpired from '@/components/common/WarningExpired';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';

function Home() {
	const { isExpiryWarning, globalStatus } = useGetGlobalStatus();

	return (
		<Container>
			{isExpiryWarning && (
				<WarningExpired expiryDate={globalStatus?.promotionExpiryDate} />
			)}
			<HomeContent />
			<Footer />
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: ${(props) => props.theme.pointColors.white};
	min-width: 1080px;
	height: 100vh;
	flex: 1;
	overflow: auto;
	position: relative;
`;

export default Home;
