import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

interface IProps {
  menuItem: IMenuItem;
}

interface IMenuItem {
  id: number;
  title: string;
  link: string[];
  uri: { active: string; notActive: string };
}

function SideMenuItem({ menuItem }: IProps) {
  const [isCurrent, setIsCurrent] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (menuItem.link.includes(pathname)) {
      setIsCurrent(true);
    } else {
      setIsCurrent(false);
    }
  }, [pathname]);

  // 임시 새로고침
  const onClickReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  return (
    <Container isCurrent={isCurrent} onClick={onClickReload}>
      <Icon
        src={isCurrent ? menuItem.uri.active : menuItem.uri.notActive}
        width='24px'
        height='24px'
      />
      <Title isCurrent={isCurrent}>{menuItem.title}</Title>
      {isCurrent && (
        <Triangle
          isWhite={pathname === '/' || pathname === '/home/notice/detail'}
        />
      )}
    </Container>
  );
}

const Container = styled.li<{ isCurrent: boolean }>`
  display: flex;
  gap: 14px;
  align-items: center;
  padding: 17px 0 17px 32px;
  position: relative;
  margin-bottom: 20px;
`;

const Icon = styled.img`
  object-fit: contain;
  margin-bottom: 2px;
`;

const Title = styled.h3<{ isCurrent: boolean }>`
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
  color: ${(props) => (props.isCurrent ? '#FFFFFF' : '#7CA7FA')};
`;
const Triangle = styled.div<{ isWhite?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-right: 16px solid
    ${(props) => (props.isWhite ? '#ffffff' : props.theme.mono[5])};
  border-bottom: 22px solid transparent;
  border-left: 16px solid transparent;
`;
export default SideMenuItem;
