import React from 'react';
import styled, { css } from 'styled-components';

import FileDownload from '@/assets/common/file_download.png';

import FlexWrap from '../common/FlexWrap';
import useRefundVatData from '@/hooks/useRefundVatData';

interface RefundVatDataItemProps {
  refundStatementData: any;
  idx: number;
  totalElements: number;
}

function RefundVatDataItem({
  refundStatementData,
  totalElements,
  idx,
}: RefundVatDataItemProps) {
  const { month, recordCategory, createDate, refundRecordIndex } =
    refundStatementData;
  const {
    recordFiles,
    checkNewFile,
    checkFileName,
    checkCreated,
    fileAllDownload,
    fileDownload,
    changeToggle,
    isToggle,
    monthHandle,
  } = useRefundVatData(refundRecordIndex);

  return (
    <>
      <Graph isToggle={isToggle}>
        <Content flex={1}>
          {checkNewFile(createDate) ? (
            <IndexText isNew>NEW</IndexText>
          ) : (
            <IndexText>{totalElements - idx}</IndexText>
          )}
        </Content>
        <Content style={{ justifyContent: 'flex-start' }} flex={15}>
          <MonthText>{monthHandle(month)}</MonthText>
          <DataText onClick={changeToggle}>
            {checkFileName(recordCategory)}
          </DataText>
        </Content>
        <Content flex={2.5}>{checkCreated(createDate)}</Content>
        <Content flex={2.5}>
          <Downalod key={idx} onClick={fileAllDownload}>
            전체 다운로드
            <DownloadIcon src={FileDownload} />
          </Downalod>
        </Content>
      </Graph>
      {recordFiles && (
        <SubItem isToggle={isToggle}>
          <>
            <Content flex={1}></Content>
            <Content flex={15} disablePadding>
              <FlexWrap dir='column' flex='1'>
                <FlexWrap dir='column' gap='15px'>
                  {recordFiles.map((file, idx) => (
                    <SubContent key={idx}>{file.fileName}</SubContent>
                  ))}
                </FlexWrap>
              </FlexWrap>
            </Content>
            <Content flex={2.5} />
            <Content flex={2.5}>
              <FlexWrap dir='column' gap='15px' alignItems='center'>
                {recordFiles.map((file, idx) => (
                  <Downalod key={idx} onClick={() => fileDownload(file)}>
                    다운로드
                    <DownloadIcon src={FileDownload} />
                  </Downalod>
                ))}
              </FlexWrap>
            </Content>
          </>
        </SubItem>
      )}
    </>
  );
}

const Graph = styled.li<{ isToggle: boolean }>`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.mono[20]};
  :last-child {
    border-bottom: none;
  }
  :last-of-type {
    border-bottom: none;
    ${(props) =>
      !props.isToggle &&
      css`
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      `}
  }
  background-color: ${(props) => props.theme.pointColors.white};
`;
const Content = styled.div<{
  flex: number;
  disablePadding?: boolean;
}>`
  display: flex;
  flex: ${(props) => props.flex};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.mono[80]};
  font-weight: 500;
`;
const IndexText = styled.span<{ isNew?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${(props) => (props.isNew ? '#E42939' : '#212121')};
`;
const MonthText = styled.span`
  margin: 0 5px 0 20px;
  padding: 0 12px;
  color: ${(props) => props.theme.blue[100]};
  font-weight: bold;
`;
const DataText = styled.span`
  position: relative;
  cursor: pointer;
  :hover {
    ::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      height: 1px;
      width: 100%;
      background-color: #212121;
    }
  }
`;
const SubItem = styled.div<{ isToggle: boolean }>`
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  max-height: 0px;
  overflow: hidden;
  transition: all 300ms ease;
  ${(props) =>
    props.isToggle &&
    css`
      max-height: 300px;
      border-bottom: 1px solid ${(props) => props.theme.mono[20]};
    `}
  :last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-bottom: none;
  }
`;
const SubContent = styled.div<{ isHeader?: boolean }>`
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
  font-size: 14px;
  line-height: 24px;
  padding-left: 96px;
  color: ${(props) => props.theme.mono[65]};
  ${(props) =>
    props.isHeader &&
    css`
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    `}
`;

const Downalod = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.blue[100]};
  cursor: pointer;
`;
const DownloadIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-left: 4px;
`;
export default RefundVatDataItem;
