import styled from 'styled-components';

import { StorageListHeader } from '@/constants/refund';
import FlexWrap from '@/components/common/FlexWrap';
import StorageItem from '@/components/refund/StorageItem';
import Typography from '@/components/common/Typography';
import { TemporaryStorageLookUpResponse } from '@/types/refund';

type Props = {
  data: TemporaryStorageLookUpResponse[];
};
function StorageList({ data }: Props) {
  return (
    <Container>
      <Header>
        {StorageListHeader.map((item) => (
          <HeaderItem key={item.key} flex={item.flex!}>
            {item.title}
          </HeaderItem>
        ))}
      </Header>
      <List>
        {data.length === 0 ? (
          <FlexWrap justifyContent='center' margin='67px 0 0 0'>
            <Typography
              size='16px'
              lineHeight='24px'
              fontWeight='500'
              color='#CBCCCE'
            >
              임시 저장된 내용이 없습니다.{' '}
            </Typography>
          </FlexWrap>
        ) : (
          data.map((item: any, idx: number) => (
            <StorageItem
              key={idx}
              item={item}
              index={idx}
              legnth={data.length}
            />
          ))
        )}
      </List>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 16px;
`;
const Header = styled.div`
  display: flex;
  background-color: #f5f6f7;
  padding: 12px 20px;
  border-bottom: 1px solid #cbccce;
`;
const HeaderItem = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  flex: ${(props) => props.flex};
  font-weight: 600;
  color: #5f6165;
`;
const List = styled.div`
  height: 560px;
  overflow: auto;
`;

export default StorageList;
