import React, { useState } from 'react';
import styled from 'styled-components';

import logoImage from '@/assets/common/logo_color.png';
import { useForm } from 'react-hook-form';
import ErrorMessage from '@/components/common/ErrorMessage';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { login } from '@/api/user';
import { ISigInPayload, ISignInResponse } from '@/types/user';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { updateUserInfo } from '@/store/modules/user';

function Login() {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm({
    defaultValues: {
      userId: '',
      password: '',
    },
  });
  const { password, userId } = watch();

  const loginMutation = useMutation<
    ISignInResponse,
    AxiosError<any>,
    ISigInPayload
  >((payload) => login(payload), {
    retry: false,
    onSuccess: (res) => {
      localStorage.setItem('loginToken', res.accessToken);
      localStorage.setItem('name', res.hospitalName);
      localStorage.setItem('profileImg', res.hospitalImagePath || '');
      localStorage.setItem('kioskPermission', res.kioskPermission ?? 'USER');
      dispatch(
        updateUserInfo({
          isLogged: true,
          productTypes: res.productTypes,
          hospitalType: res.hospitalType,
          hospitalId: res.hospitalId,
          isKioskStore: res.kioskId,
          hospitals: res.hospitals || [],
        }),
      );
    },
    onError: (error) => {
      console.log('에러', error);
      const code = error.response?.data.code;
      const messgae = error.response?.data.message;
      if (code === 'H0001') {
        setError('userId', {
          message: '등록되지 않은 사업자번호 입니다.',
        });
      }

      if (messgae === 'Invalid Password') {
        setError('password', {
          message: '비밀번호가 틀립니다.',
        });
      }
    },
  });

  const onSubmit = async ({ userId, password }: ISigInPayload) => {
    const payload = {
      userId,
      password,
    };
    loginMutation.mutate(payload);
  };

  return (
    <Wrapper>
      <LoginContainer>
        <Container>
          <Header>
            <Logo src={logoImage} />
            <Title>의료용역 환급 서비스</Title>
          </Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputField>
              <Label htmlFor='login-id'>
                사업자 등록번호 또는 법인사업자번호
              </Label>
              <Input
                id='login-id'
                {...register('userId', {
                  required: '아이디를 입력하세요.',
                })}
                maxLength={10}
                type='text'
                placeholder='뒤 5자리를 입력해주세요.'
              />
              {errors.userId && <ErrorMessage text={errors.userId.message} />}
            </InputField>

            <InputField>
              <Label htmlFor='login-password'>비밀번호</Label>
              <Input
                id='login-password'
                {...register('password', {
                  required: '비밀번호를 입력하세요.',
                })}
                type='password'
                placeholder='비밀번호를 입력해주세요.'
              />
              {errors.password && (
                <ErrorMessage text={errors.password.message} />
              )}
            </InputField>
            <Button disabled={!(password && userId)}>로그인</Button>
          </Form>
          <LostIdPw>아이디/비밀번호 분실시</LostIdPw>
          <GuideText>{`아이디는 병원 사업자 등록번호 혹은 법인 번호입니다.\n비밀번호 분실시 02-6275-8011로 문의 바랍니다.`}</GuideText>
        </Container>
      </LoginContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  min-height: calc(100vh);
  background-color: ${(props) => props.theme.mono[10]};
`;

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 72px 60px 42px 60px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.pointColors.white};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 200px;
  height: 55px;
`;

const Title = styled.h2`
  margin: 16px 0 32px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.large};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Input = styled.input`
  padding: 13px 11px;
  border-radius: 4px;
  width: 300px;
  height: 48px;
  color: ${(props) => props.theme.grayColors.scale1};
  border: 2px solid ${(props) => props.theme.mono[20]};
  :focus {
    border: 2px solid ${(props) => props.theme.blue[100]};
    background-color: ${(props) => props.theme.blue[5]};
  }
  ::placeholder {
    color: ${(props) => props.theme.grayColors.scale3};
  }
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 12px 0;
  border-radius: 8px;
  color: ${(props) => props.theme.pointColors.white};
  background-color: ${(props) => props.theme.blue[100]};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
  :disabled {
    background-color: ${(props) => props.theme.blue[20]};
  }
`;

const LostIdPw = styled.p`
  margin-top: 32px;
  text-align: center;
  color: ${(props) => props.theme.mono[50]};
`;
const GuideText = styled.p`
  white-space: pre-wrap;
  color: ${(props) => props.theme.mono[50]};
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  margin-top: 4px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #3a3b3e;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export default Login;
