import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import chevronIcon from '@/assets/common/chevron_right.png';
import {
  RefundHomeFullPaymentHeader,
  RefundHomeHeader,
} from '@/constants/refundGraph';
import { IHomeCalendarResponae, LatestRefundInfo } from '@/types/home';
import { comma } from '@/util/format';
import Button from '../common/Button';
import FlexWrap from '../common/FlexWrap';

import { useAppSelector } from '@/hooks/useReduxHooks';
import { reset } from '@/store/modules/refund';
import DetailModal from '../refundDetail/DetailModal';

interface IProps {
  medicalInfo: IHomeCalendarResponae;
}

export interface IModal {
  data?: { refundIndex?: number | string };
  isActive: boolean;
}

function RecentRefund({ medicalInfo }: IProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAutoPayment, hospitals } = useAppSelector((state) => state.user);
  const [detailModal, setDetailModal] = useState<IModal>({
    isActive: false,
  });
  const { latestRefundInfo } = medicalInfo;
  const onClick = (item: LatestRefundInfo) => {
    setDetailModal({ data: item, isActive: true });
  };

  return (
    <Container dir='column'>
      <TitleContainer justifyContent='space-between' alignItems='center'>
        <Title>최근 환급내역</Title>
        <Button
          text='+ 더보기'
          color='#757575'
          fontWeight
          onClick={() => {
            navigate('/refund-detail');
            dispatch(reset());
          }}
        />
      </TitleContainer>
      {!latestRefundInfo[0] ? (
        <NoContent>해당 기간의 환급 내용이 없습니다.</NoContent>
      ) : (
        <>
          <Header>
            {(isAutoPayment
              ? RefundHomeHeader
              : RefundHomeFullPaymentHeader
            ).map((item) => (
              <Fragment key={item.id}>
                {hospitals.length === 0 && item.title === '구분' ? (
                  <></>
                ) : (
                  <HeaderTitle flex={item.flex}>{item.title}</HeaderTitle>
                )}
              </Fragment>
            ))}
          </Header>
          <RefundList>
            {latestRefundInfo.map((item, index) => (
              <RefundItem key={item.refundIndex}>
                <Content flex={1}>{'0' + Number(index + 1)}</Content>
                {hospitals.length > 0 && (
                  <Content flex={1.5}>{item.hospitalName}</Content>
                )}
                <Content flex={2.5}>{item.supplyDate}</Content>
                <Content flex={2.5}>{item.nation}</Content>
                <Content flex={2.5}>{item.touristName}</Content>
                {isAutoPayment ? (
                  <>
                    <Content flex={2.5}>{comma(item.totalPrice)} 원</Content>
                    <Content flex={2.5}>{comma(item.refund)} 원</Content>
                    <Content flex={2.5}>
                      {comma(
                        item.paymentType === 'ORIGINAL'
                          ? +item.totalPrice - +item.refund
                          : +item.totalPrice
                      )}{' '}
                      원
                    </Content>
                  </>
                ) : (
                  <>
                    <Content flex={2.5}>{comma(item.totalPrice)} 원</Content>
                    <Content flex={2.5}>{comma(item.refund)} 원</Content>
                  </>
                )}
                <Content flex={2.5}>
                  <Button
                    text='보기'
                    width='100px'
                    height='32px'
                    borderRadius='4px'
                    fontWeight
                    onClick={() => onClick(item)}
                    gap='6px'
                  >
                    <ChevronIcon src={chevronIcon} />
                  </Button>
                </Content>
              </RefundItem>
            ))}
          </RefundList>
        </>
      )}

      {detailModal.isActive && (
        <DetailModal
          refund={detailModal.data}
          setUploadModal={setDetailModal}
        />
      )}
    </Container>
  );
}

const Container = styled(FlexWrap)`
  position: relative;
  margin-top: 28px;
  @media ${(props) => props.theme.laptop} {
    margin-top: 0px;
  }
`;

const TitleContainer = styled(FlexWrap)`
  width: 100%;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
  margin-bottom: 16px;
  @media ${(props) => props.theme.largeView} {
    margin-bottom: 24px;
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.mono[5]};
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const HeaderTitle = styled.p<{ flex?: number }>`
  padding: 12px 0;
  flex: ${(props) => props.flex};
  text-align: center;
  color: ${(props) => props.theme.mono[65]};
  font-size: ${(props) => props.theme.fontSize.xSmall};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  line-height: ${(props) => props.theme.lineHeight.xSmall};
`;
const NoContent = styled.p`
  padding: 32px;
  background-color: ${(props) => props.theme.mono[5]};
  width: 100%;
  border-radius: 16px;
  margin-top: 20px;
  color: ${(props) => props.theme.mono[65]};
`;
const RefundList = styled.ul`
  width: 100%;
  background-color: ${(props) => props.theme.pointColors.white};
`;

const RefundItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.mono[10]};
`;

const Content = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  flex: ${(props) => props.flex};
  font-weight: 500;
  padding: 12px 0;
`;
const ChevronIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export default RecentRefund;
