import styled from 'styled-components';

import { IMedicalResponse } from '@/types/medical';
import FlexWrap from '../common/FlexWrap';

interface IProps {
  medicalData: IMedicalResponse;
}

function MedicalInfo({ medicalData }: IProps) {
  return (
    <Container>
      <FlexWrap dir='column' gap='12px' flex='1'>
        <InputContainer>
          <Label>사업자등록증번호</Label>
          <Input value={medicalData?.hospitalInfoDto.businessNumber} />
        </InputContainer>
        <InputContainer>
          <Label>의료기관명</Label>
          <Input value={medicalData?.hospitalInfoDto.hospitalName} />
        </InputContainer>
        <InputContainer>
          <Label>대표자명</Label>
          <Input value={medicalData?.hospitalInfoDto.managerName} />
        </InputContainer>
      </FlexWrap>
      <FlexWrap dir='column' gap='12px' flex='1'>
        <InputContainer>
          <Label>유치기관등록번호</Label>
          <Input value={medicalData?.hospitalInfoDto.taxFreeNumber} />
        </InputContainer>
        <InputContainer>
          <Label>전화번호</Label>
          <Input value={medicalData?.hospitalInfoDto.hospitalTelNumber} />
        </InputContainer>
        <InputContainer>
          <Label>소재지</Label>
          <Input value={medicalData?.hospitalInfoDto.hospitalAddress} />
        </InputContainer>
      </FlexWrap>
    </Container>
  );
}

const Container = styled(FlexWrap)`
  display: flex;
  gap: 100px;
  padding: 28px 20px 28px 24px;
  background-color: ${(props) => props.theme.pointColors.white};
  border-radius: 16px;
  @media ${(props) => props.theme.largeView} {
    padding: 28px 20px 28px 32px;
    gap: 130px;
  }
`;

const InputContainer = styled.div<{ address?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
`;
const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.mono[80]};
  width: 160px;
  padding-left: 16px;
`;
const Input = styled.input.attrs({ disabled: true })`
  padding: 13px 10px;
  border-radius: 4px;
  width: 100%;
  background-color: ${(props) => props.theme.mono[5]};
  border: none;
`;

export default MedicalInfo;
