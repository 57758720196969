import { isWithinInterval } from 'date-fns';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Play from '@/assets/kiosk/play.png';
import Pause from '@/assets/kiosk/pause.png';
import Preview from '@/assets/kiosk/preview.png';
import Delete from '@/assets/kiosk/delete.png';
import PreviewModal from '@/components/kiosk/PreviewModal';
import DeleteModal from '@/components/kiosk/DeleteModal';
import PlayModal from '@/components/kiosk/PlayModal';
import useActivateKioskVideo from '@/hooks/useActivateKioskVideo';

type Props = {
  idx: number;
  length: number;
  data: {
    kioskVideoId: number;
    fileName: string;
    s3Url: string;
    uploadedDate: string;
    activated: boolean;
    createdDate: string;
  };
};
function VideoListItem({ idx, length, data }: Props) {
  const {
    kioskVideoId,
    fileName,
    s3Url,
    uploadedDate,
    activated,
    createdDate,
  } = data;
  const [isActive, setIsActive] = useState(activated);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenPlayModal, setIsOpenPlayModal] = useState(false);
  const { mutate: activateMutate } = useActivateKioskVideo({});

  useEffect(() => {
    setIsActive(activated);
  }, [activated]);

  const onClickToggleBtn = () => {
    if (!isActive) {
      onTogglePlayModal();
      return;
    }
    setIsActive(false);
    activateMutate({ kioskVideoId, activate: false });
  };
  const onTogglePreviewModal = () => {
    setIsOpenPreview((prev) => !prev);
  };
  const onToggleDeleteModal = () => {
    setIsOpenDeleteModal((prev) => !prev);
  };
  const onTogglePlayModal = () => {
    setIsOpenPlayModal((prev) => !prev);
  };
  const isWithin7DaysFromCreatedDate = (createdDate: string) => {
    const now = new Date();
    const sevenDaysAfterCreated = new Date(
      new Date(createdDate).setDate(new Date(createdDate).getDate() + 7)
    );

    return isWithinInterval(now, {
      start: new Date(createdDate),
      end: sevenDaysAfterCreated,
    });
  };
  return (
    <>
      <Row>
        <Cell isNew={idx === 0 && isWithin7DaysFromCreatedDate(createdDate)}>
          {idx === 0 && isWithin7DaysFromCreatedDate(createdDate)
            ? 'New'
            : length - idx}
        </Cell>
        <Cell flex='5' leftArrange>
          {fileName}
        </Cell>
        <Cell>{uploadedDate.replaceAll('-', '.')}</Cell>
        <Cell flex='2'>
          <Button onClick={onTogglePreviewModal}>
            <Icon src={Preview} /> 미리보기
          </Button>
        </Cell>
        <Cell>
          <ToggleBtn active={isActive} onClick={onClickToggleBtn}>
            <Controller src={isActive ? Play : Pause} active={isActive} />
          </ToggleBtn>
        </Cell>
        <Cell>
          {!isActive && (
            <Button onClick={onToggleDeleteModal}>
              <Icon src={Delete} />
            </Button>
          )}
        </Cell>
      </Row>
      {isOpenPreview && (
        <PreviewModal
          fileName={fileName}
          s3Url={s3Url}
          onClose={onTogglePreviewModal}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          fileName={fileName}
          onClose={onToggleDeleteModal}
          kioskVideoId={kioskVideoId}
        />
      )}
      {isOpenPlayModal && (
        <PlayModal
          fileName={fileName}
          onClose={onTogglePlayModal}
          kioskVideoId={kioskVideoId}
          setIsActive={setIsActive}
        />
      )}
    </>
  );
}

const Row = styled.div`
  width: 100%;
  display: flex;
  background-color: #ffffff;
  border-bottom: 1px solid ${(props) => props.theme.mono[10]};
  :last-of-type {
    border-bottom: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;
const Cell = styled.div<{
  flex?: string;
  leftArrange?: boolean;
  isNew?: boolean;
}>`
  display: flex;
  flex: ${(props) => props.flex || 1};
  align-items: center;
  padding: 0 20px;
  height: 48px;
  justify-content: ${(props) => (props.leftArrange ? 'flex-start' : 'center')};
  color: ${(props) => (props.isNew ? '#FF2D55' : '#3A3B3E')};
  font-weight: 500;
`;
const Button = styled.button`
  font-size: 14px;
  color: #246cf6;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
const ToggleBtn = styled.button<{ active: boolean }>`
  width: 56px;
  height: 32px;
  border-radius: 100px;
  background-color: ${(props) => (props.active ? '#5089F8' : '#E5E6E8')};
  padding: 4px;
  display: flex;
  margin: 0 auto;
`;
const Icon = styled.img<{ disabled?: boolean }>`
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
`;
const Controller = styled.img<{ active: boolean }>`
  background-color: #ffff;
  width: 24px;
  height: 24px;
  padding: 3px;
  border-radius: 50%;
  transform: ${(props) =>
    props.active ? 'translateX(24px)' : 'translateX(0)'};
  transition: 300ms all ease-in;
`;
export default VideoListItem;
