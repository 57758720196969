import React from 'react';
import styled from 'styled-components';

import useStatement from '@/hooks/useStatement';
import FlexWrap from '../common/FlexWrap';
import Paging from '../common/Paging';
import RequiredData from './RequiredData';
import RefundVatData from './RefundVatData';
import LoadingView from '../common/LoadingView';

function RefundStatementContent() {
  const {
    data: refundStatementData,
    totalPage,
    totalElements,
    setPageNumber,
    isLoading,
  } = useStatement();

  return (
    <Container>
      <SubContainer>
        <SubTitle>필수 자료</SubTitle>
        <RequiredData />
      </SubContainer>
      <SubContainer>
        <FlexWrap justifyContent='space-between'>
          <SubTitle>환급/부가세 신고자료</SubTitle>
          <CautionText>
            * 부가가치세신고자료는 개인사업자 1월, 7월 / 법인사업자는 1월, 4월,
            7월, 10월에 업로드됩니다.
          </CautionText>
        </FlexWrap>
        {isLoading ? (
          <LoadingView height='30vh' />
        ) : (
          <>
            {refundStatementData && (
              <RefundVatData
                refundStatementData={refundStatementData.content}
                totalElements={totalElements}
              />
            )}
          </>
        )}
      </SubContainer>
      <Paging setPageNumber={setPageNumber} totalPage={totalPage} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 22px 40px 85px 40px;
`;

const SubContainer = styled.div`
  padding: 36px 0px;
`;
const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
  margin-bottom: 16px;
`;
const CautionText = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.mono[65]};
`;
export default RefundStatementContent;
