import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

import { IHomeCalendarResponae } from '@/types/home';
import MedicalRefundInfo from './MedicalRefundInfo';
import DateSelector from '../common/DateSelector';
import SupplyFilter from '../common/SupplyFilter';
import FlexWrap from '../common/FlexWrap';
import HospitalsFilter from '../common/HospitalsFilter';
import { useAppSelector } from '@/hooks/useReduxHooks';

interface IProps {
  medicalInfo: IHomeCalendarResponae;
}

function HomeHeader({ medicalInfo }: IProps) {
  const { hospitals } = useAppSelector((state) => state.user);
  return (
    <Container>
      <Header>
        <Title>환급 현황</Title>
        <FlexWrap gap='10px'>
          {hospitals.length > 0 && <HospitalsFilter />}
          <SupplyFilter />
          <DateSelector />
        </FlexWrap>
      </Header>
      <MedicalRefundInfo medicalInfo={medicalInfo} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 55px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
`;

export default HomeHeader;
