import React from 'react';
import styled from 'styled-components';

interface IProps {
  width?: string;
  height?: string;
  text?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  fontSize?: string;
  backgroundColor?: string;
  color?: string;
  disabled?: boolean;
  margin?: string;
  fontWeight?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  gap?: string;
  reverse?: boolean;
}

function Button({
  color,
  text,
  fontWeight = false,
  children,
  ...buttonProps
}: IProps) {
  return (
    <BtnContainer {...buttonProps}>
      <Text color={color} bold={fontWeight}>
        {text}
      </Text>
      {children}
    </BtnContainer>
  );
}

const BtnContainer = styled.button<{
  width?: string;
  height?: string;
  padding?: string;
  backgroundColor?: string;
  borderRadius?: string;
  border?: string;
  margin?: string;
  gap?: string;
  reverse?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width && props.width};
  height: ${(props) => props.height && props.height};
  border: ${(props) => props.border && props.border};
  padding: ${(props) => props.padding && props.padding};
  margin: ${(props) => props.margin && props.margin};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor};
  gap: ${(props) => props.gap || '0px'};
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
`;

const Text = styled.span<{ color?: string; bold?: boolean }>`
  color: ${(props) => props.color && props.color};
  font-weight: ${(props) => props.bold && props.theme.fontWeight.medium};
`;

export default Button;
