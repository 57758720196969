import React from 'react';
import styled from 'styled-components';

import foreignerIcon from '@/assets/Home/foreigner.png';
import paymentIcon from '@/assets/Home/payment.png';
import refundIcon from '@/assets/Home/refund.png';
import FlexWrap from '@/components/common/FlexWrap';
import Text from '@/components/common/Text';
import { IHomeCalendarResponae } from '@/types/home';
import { comma } from '@/util/format';

interface IProps {
  medicalInfo: IHomeCalendarResponae;
}

function MedicalRefundInfo({ medicalInfo }: IProps) {
  const { refundSummary } = medicalInfo;
  const data = [
    {
      title: '외국인 관광객 유치수',
      iconUrl: foreignerIcon,
      value: refundSummary.totalCount.toString(),
      unit: '명',
    },
    {
      title: '누적 결제 금액',
      iconUrl: paymentIcon,
      value: comma(refundSummary.totalPrice),
      unit: '원',
    },
    {
      title: '누적 환급액',
      iconUrl: refundIcon,
      value: comma(refundSummary.totalRefund),
      unit: '원',
    },
  ];

  return (
    <Container>
      {data.map(({ title, iconUrl, value, unit }, idx) => (
        <RefundItem key={idx}>
          <Icon src={iconUrl} />
          <Text text={title} fontWeight='700' fontSize='18px' />
          <FlexWrap alignItems='center' gap='4px'>
            <Text
              text={value}
              color='#246CF6'
              fontSize='32px'
              fontWeight='700'
            />
            <Text text={unit} fontSize='18px' color='#5F6165' />
          </FlexWrap>
        </RefundItem>
      ))}
    </Container>
  );
}

const Container = styled(FlexWrap)`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const RefundItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.mono[5]};
  padding: 16px;
  gap: 4px;
  border-radius: 16px;
`;

const Icon = styled.img`
  width: 80px;
  height: 80px;
`;

export default MedicalRefundInfo;
