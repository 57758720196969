import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';

import printerIcon from '@/assets/refund/printer.png';
import {
  RefundDetailFullPaymentHeader,
  RefundDetailHeader,
} from '@/constants/refundGraph';
import FlexWrap from '@/components/common/FlexWrap';
import PrintElement from '@/components/refund/PrintElement';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { getComma } from '@/util/format';

function DetailModal({ setUploadModal, refund }: any) {
  const printRef = useRef(null);
  const { isAutoPayment } = useAppSelector((state) => state.user);

  return (
    <Wrapper>
      <BackDrop onClick={() => setUploadModal(false)} />
      <Container>
        <FlexWrap justifyContent='space-between' padding='32px'>
          <Title>의료용역공급확인서</Title>
          <ButtonWrapper>
            <Button onClick={() => setUploadModal(false)}>닫기</Button>
            <ReactToPrint
              trigger={() => (
                <Button isPrint>
                  <Printer src={printerIcon} />
                  출력하기
                </Button>
              )}
              content={() => printRef.current}
            />
          </ButtonWrapper>
        </FlexWrap>
        <ContentContainer>
          <Content isHeader>
            {(isAutoPayment
              ? RefundDetailHeader
              : RefundDetailFullPaymentHeader
            ).map((item) => (
              <ContentText key={item.id} isHeader>
                {item.title}
              </ContentText>
            ))}
          </Content>
          <Content>
            <ContentText>{refund.supplyDate}</ContentText>
            <ContentText>{refund.nation}</ContentText>
            <ContentText>{refund.touristName}</ContentText>
            {isAutoPayment ? (
              <>
                <ContentText textRight>
                  {getComma(refund.totalPrice)}원
                </ContentText>
                <ContentText textRight>{getComma(refund.refund)}원</ContentText>
                <ContentText isColor>
                  {getComma(
                    refund.paymentType === 'ORIGINAL'
                      ? +refund.totalPrice - +refund.refund
                      : +refund.totalPrice,
                  )}
                  원
                </ContentText>
              </>
            ) : (
              <>
                <ContentText>{getComma(refund.totalPrice)}원</ContentText>
                <ContentText textRight>{getComma(refund.refund)}원</ContentText>
              </>
            )}
          </Content>
        </ContentContainer>
        <div ref={printRef} style={{ marginTop: -50 }}>
          <PrintElement
            touristIndex={refund.touristIndex}
            refundIndex={refund.refundIndex}
          />
        </div>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33, 33, 33, 0.4);
  z-index: 20;
  overflow: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  min-width: 1200px;
  height: 80%;
  overflow-y: scroll;
  border-radius: 8px;
  background-color: ${(props) => props.theme.pointColors.white};
`;

const Title = styled.h2`
  font-size: 21px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 150%;
  color: ${(props) => props.theme.mono[80]};
`;
const ButtonWrapper = styled(FlexWrap)`
  gap: 24px;
  @media ${(props) => props.theme.largeView} {
    gap: 32px;
  }
`;
const Button = styled.button<{ isPrint?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 11px;
  border-radius: 8px;
  width: ${(props) => (props.isPrint ? '300px' : '180px')};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  color: ${(props) =>
    props.isPrint ? props.theme.pointColors.white : '#212121'};
  background-color: ${(props) =>
    props.isPrint ? props.theme.blue[100] : props.theme.mono[20]};
`;

const ContentContainer = styled.div`
  margin-bottom: 12px;
  border-radius: 4px;
  padding: 0 32px;
`;

const Content = styled.div<{ isHeader?: boolean }>`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid
    ${(props) => (props.isHeader ? props.theme.mono[20] : props.theme.mono[10])};
`;

const ContentText = styled.p<{
  isHeader?: boolean;
  textRight?: boolean;
  isColor?: boolean;
}>`
  padding: 12px 0;
  flex: 1;
  text-align: center;
  color: ${(props) => props.theme.mono[props.isHeader ? 65 : 80]};
  font-size: ${(props) => props.isHeader && props.theme.fontSize.small};
  font-weight: ${(props) => !props.isHeader && props.theme.fontWeight.semiBold};
  line-height: ${(props) => props.isHeader && props.theme.lineHeight.small};
`;
const Printer = styled.img`
  width: 24px;
  height: 24px;
`;
const BackDrop = styled.div<{ zIndex?: number }>`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.zIndex};
`;

export default DetailModal;
