import React from 'react';
import styled from 'styled-components';

interface IProps {
  text: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  color?: string;
  hoverColor?: string;
}

function Text({
  text,
  fontSize,
  lineHeight,
  fontWeight,
  color,
  hoverColor,
}: IProps) {
  return (
    <Content
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      color={color}
      hoverColor={hoverColor}
    >
      {text}
    </Content>
  );
}

const Content = styled.p<{
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  color?: string;
  hoverColor?: string;
  inline?: boolean;
}>`
  font-size: ${(props) => props.fontSize && props.fontSize};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
  line-height: ${(props) => props.lineHeight || '150%'};
  color: ${(props) => props.color && props.color};
  :hover {
    color: ${(props) => props.hoverColor && props.hoverColor};
  }
`;

export default Text;
