import Button from '@/components/common/Button';
import FlexWrap from '@/components/common/FlexWrap';
import ICON_ERROR from '@/assets/common/error.png';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';

type Props = {
  onClose: () => void;
};

const PorteOverPriceModal = ({ onClose }: Props) => {
  const modalRef = useRef<HTMLButtonElement | null>(null);
  const modalEl = modalRef.current;

  // 가격 입력 포커스 시간 고려
  useEffect(() => {
    if (modalEl) {
      setTimeout(() => modalEl.focus(), 200);
    }
  }, [modalEl]);

  return (
    <BackDrop>
      <Container dir='column' alignItems='center'>
        <TitleWrapper>
          <img src={ICON_ERROR} alt='' width={26} height={26} />
          <Title>시술 항목 당 결제금액 550만원을 초과할 수 없습니다.</Title>
        </TitleWrapper>
        <ConfirmButton type='button' ref={modalRef} onClick={onClose}>
          확인했습니다.
        </ConfirmButton>
      </Container>
    </BackDrop>
  );
};

export default PorteOverPriceModal;

const TitleWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const Title = styled.strong`
  color: var(--Mono-80, #3a3b3e);

  /* WebHead/18_BD */
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  margin-bottom: 50px;
`;

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(33, 33, 33, 0.4);
  z-index: 10;
`;

const Container = styled(FlexWrap)`
  border-radius: 12px;
  background-color: #fff;
  padding: 25px;
  @media ${(props) => props.theme.largeView} {
    padding: 24px;
  }
`;

const ConfirmButton = styled.button`
  border-radius: 8px;
  background-color: #246cf6;
  color: #fff;
  width: 280px;
  height: 42px;
`;
