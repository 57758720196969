import {
  IRefundPayload,
  IRefundResponse,
  IExcelBody,
  IExcelResponse,
  IParams,
  IDownloadResponse,
  IRefundEditResponse,
  IRefundEditRequestResponse,
  IRefundCanclePayload,
  IRefundDetailResponse,
  TemporaryStorageLookUpResponse,
  StoragePayload,
} from '@/types/refund';

import api from '..';
import { getFormattedTableData } from '@/util/format';
// 환급 신청
export const refundRegister = async (payload: IRefundPayload) => {
  const res = await api.postAxios<IRefundResponse, IRefundPayload, null>(
    '/user/refund',
    payload,
  );
  return res.data;
};

// 포르테 환급 신청
export const refundRegisterPorte = async (payload: any) => {
  const res = await api.postAxios<IRefundResponse, IRefundPayload, null>(
    '/user/refund/group',
    payload,
  );
  return res.data;
};

//엑셀 데이터 조회
export const fetchExcelData = async (payload: IExcelBody) => {
  const res = await api.postAxios<IExcelResponse, IExcelBody, null>(
    '/user/excel',
    payload,
  );
  return getFormattedTableData(res.data);
};

// 환급내역
export const refundDetail = async (params: IParams) => {
  const res = await api.getAxios<IRefundDetailResponse, IParams>(
    `/user/refund/history`,
    params,
  );
  return res.data;
};
// 환급내역 상위병원
export const refundSuperiorDetail = async (params: IParams) => {
  const res = await api.getAxios<IRefundDetailResponse, IParams>(
    `/superior/refund/history`,
    params,
  );
  return res.data;
};

// 업로드
export const upload = async (payload: IRefundPayload) => {
  const res = await api.postAxios<null, any, null>(
    `/user/refund/upload`,
    payload,
  );
  return res.data;
};

// 환급취소
export const refundCancle = async (payload: IRefundCanclePayload) => {
  const res = await api.patchAxios<null, IRefundCanclePayload, null>(
    `/user/refund/cancel`,
    payload,
  );
  return res.data;
};

// 환급 수정 데이터 불러오기
export const refundEditRequest = async (payload: number) => {
  const res = await api.getAxios<IRefundEditRequestResponse, number>(
    `/user/refund/update/${payload}`,
  );
  return res.data;
};

// 환급 수정
export const refundEdit = async (
  refundIndex: string,
  payload: IRefundPayload,
) => {
  const res = await api.patchAxios<IRefundEditResponse, IRefundPayload, null>(
    `/user/refund/update/${refundIndex}`,
    payload,
  );
  return res.data;
};

// 다운로드
export const refundDocDownload = async (payload: number) => {
  const res = await api.getAxios<IDownloadResponse, number>(
    `/user/refund/download/${payload}`,
  );
  return res.data;
};
// 환급 내역서
export const getRefundStatement = async () => {
  const res = await api.getAxios<any, null>(`/user/record`);
  return res.data;
};
// 환급 내역서 각 파일정보
export const getRefundRecordDetail = async (refundRecordIndex: number) => {
  const res = await api.getAxios<any[], null>(
    `/user/record/detail/${refundRecordIndex}`,
  );
  return res.data;
};

// 임시저장 요청
export const temporaryStorageSave = async (payload: StoragePayload) => {
  const res = await api.postAxios<number, StoragePayload, null>(
    '/user/temp/save',
    payload,
  );
  return res.data;
};
// 임시저장 전체 리스트 조회
export const temporaryAllStorageLookUp = async () => {
  const res = await api.getAxios<any, number>(`/user/temp`);
  return res.data;
};

// 임시저장 리스트 조회
export const temporaryStorageLookUp = async (hospitalId: number) => {
  const res = await api.getAxios<TemporaryStorageLookUpResponse[], number>(
    `/user/temp/${hospitalId}`,
  );
  return res.data;
};

// 임시저장 전체 삭제
export const temporaryStorageAllRemove = async (hospitalId: number) => {
  const res = await api.deleteAxios<number, null, null>(
    `/user/temp/${hospitalId}`,
  );
  return res.data;
};

// 임시저장 전체 삭제
export const temporaryStorageRemove = async (
  hospitalId: number,
  tempInfoId: number,
) => {
  const res = await api.deleteAxios<number, null, null>(
    `/user/temp/${hospitalId}/${tempInfoId}`,
  );
  return res.data;
};

export const excelDownload = async (queryString: any) => {
  const res = await api.getAxios<any, any>(
    `/user/refund/excel/download`,
    queryString,
    { responseType: 'blob' },
  );
  return res.data;
};
