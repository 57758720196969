import { useState } from 'react';
import styled from 'styled-components';

import FlexWrap from '../common/FlexWrap';
import Icon from '../common/Icon';
import Typography from '../common/Typography';
import closeIcon from '@/assets/common/cancle.png';
import removeIcon from '@/assets/common/remove.png';
import StorageList from './StorageList';
import { storageModalState } from '@/store/modules/validate';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { useMutation, useQueryClient } from 'react-query';
import { temporaryStorageAllRemove } from '@/api/refund';
import RemoveModal from './RemoveModal';
import { TemporaryStorageLookUpResponse } from '@/types/refund';

type Props = {
  data: TemporaryStorageLookUpResponse[];
};
function TemporaryStorageModal({ data }: Props) {
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const dispatch = useAppDispatch();
  const { hospitalId } = useAppSelector((state) => state.user);
  const queryClient = useQueryClient();

  const { mutate: allRemoveMutaion } = useMutation(
    () => temporaryStorageAllRemove(hospitalId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('allStorage');
        setIsRemoveModal(false);
      },
    },
  );

  const onAllRemove = () => {
    allRemoveMutaion();
  };

  return (
    <BackDrop>
      <Container>
        <FlexWrap justifyContent='space-between'>
          <Typography size='21px' fontWeight='700' lineHeight='31.5px'>
            임시 저장
          </Typography>
          <CloseBtn onClick={() => dispatch(storageModalState(false))}>
            <Icon imgUrl={closeIcon} width='24px' height='24px' />
          </CloseBtn>
        </FlexWrap>
        <FlexWrap
          alignItems='center'
          justifyContent='space-between'
          margin='16px 0 0 0'
        >
          <Typography size='16px' fontWeight='500' lineHeight='24px'>
            최대 20건까지 저장됩니다.
          </Typography>
          <AllRemoveBtn onClick={() => setIsRemoveModal(true)}>
            <Icon imgUrl={removeIcon} width='16px' height='16px' />
            <Typography color='#5F6165'>전체 삭제</Typography>
          </AllRemoveBtn>
        </FlexWrap>
        <StorageList data={data} />
      </Container>
      {isRemoveModal && (
        <RemoveModal
          title='전체 삭제하기'
          text='임시 저장 내역 전체를 삭제하시겠습니까?'
          setIsRemoveModal={setIsRemoveModal}
          onRemove={onAllRemove}
        />
      )}
    </BackDrop>
  );
}

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
`;
const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 760px;
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
  overflow: hidden;
`;
const AllRemoveBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const CloseBtn = styled.button`
  :hover {
    opacity: 0.8;
  }
`;
export default TemporaryStorageModal;
