import {
  ActivateVideoBody,
  ActivateVideoPayload,
  DeleteAllVideoPayload,
  DeleteVideoPayload,
  FetchVideoListResponse,
  KioskTypeParams,
  UploadVedioPayload,
} from '@/types/kiosk';
import api from '..';

export const fetchVideoList = async (kioskId: number | boolean) => {
  const parmas = { type: 'HOSPITAL' };
  const res = await api.getAxios<FetchVideoListResponse, any>(
    `/kiosk/video/${kioskId}`,
    parmas
  );
  return res.data;
};
export const uploadVideo = async (body: UploadVedioPayload) => {
  const { formData, kioskId } = body;
  const res = await api.postAxios<FetchVideoListResponse, FormData, null>(
    `/kiosk/video/${kioskId}`,
    formData,
    null
  );
  return res.data;
};
export const activateVideo = async ({
  kioskVideoId,
  activate,
}: ActivateVideoPayload) => {
  const res = await api.patchAxios<null, ActivateVideoBody, null>(
    `/kiosk/video/activate/${kioskVideoId}`,
    { activate }
  );
  return res.data;
};
export const deleteVideo = async (payload: DeleteVideoPayload) => {
  const { kioskVideoId, kioskId, type } = payload;
  const params = { type };
  const res = await api.deleteAxios<null, null, KioskTypeParams>(
    `/kiosk/video/${kioskId}/${kioskVideoId}`,
    null,
    params
  );
  return res.data;
};

export const deleteAllVideos = async (payload: DeleteAllVideoPayload) => {
  const { kioskId, type } = payload;
  const params = { type };
  const res = await api.deleteAxios<null, null, KioskTypeParams>(
    `/kiosk/video/${kioskId}`,
    null,
    params
  );
  return res.data;
};
