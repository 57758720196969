import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import { refundCancle } from '@/api/refund';
import DeleteIcon from '@/assets/refund/bin.png';
import Button from '@/components/common/Button';

import { IModal } from '@/components/home/RecentRefund';
import CustomModal from '@/components/common/CustomModal';
import FlexWrap from '@/components/common/FlexWrap';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { IRefundCanclePayload, IRefundDetailContent } from '@/types/refund';
import { getComma } from '@/util/format';
import { refundStatusDivision } from '@/util/common';
import DetailModal from '@/components/refundDetail/DetailModal';

interface IProps {
  refund: IRefundDetailContent;
  index: number;
  currentPage: number;
  refetch: any;
  showKioskPrice: boolean;
}

function RefundDetailItem({
  refund,
  index,
  currentPage,
  refetch,
  showKioskPrice = false,
}: IProps) {
  const {
    hospitalName,
    nation,
    paymentType,
    refund: refundPrice,
    refundIndex,
    supplyDate,
    totalPrice,
    touristName,
    canCancel,
    isTransfer,
    extraRefund,
    refundHistoryStatus,
  } = refund;
  const { isKioskStore } = useAppSelector((state) => state.user);
  const [isModal, setIsModal] = useState<any>({ isActive: false });
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState<IModal>({
    isActive: false,
  });
  const refundCancleMutation = useMutation<
    null,
    AxiosError,
    IRefundCanclePayload
  >('refundCancle', (payload) => refundCancle(payload), {
    retry: false,
    onSuccess: () => {
      refetch();
      onToggleDeleteModal();
    },
    onError: (error) => {
      console.log('에러', error);
    },
  });

  const { isAutoPayment, hospitals } = useAppSelector((state) => state.user);
  const onToggleDeleteModal = () => {
    setIsDeleteModal((prev) => !prev);
  };

  const onClickRefundCancle = () => {
    refundCancleMutation.mutate({ cancelList: [refundIndex] });
  };

  return (
    <Item>
      <Content active={refundHistoryStatus !== 'CANCEL'} flex={0.7}>
        {index + 1}
      </Content>
      {hospitals.length > 0 && (
        <Content active={refundHistoryStatus !== 'CANCEL'} flex={1.2}>
          {hospitalName}
        </Content>
      )}
      <Content active={refundHistoryStatus !== 'CANCEL'} flex={1.8}>
        {refundStatusDivision(refundHistoryStatus)}
      </Content>
      <Content active={refundHistoryStatus !== 'CANCEL'} flex={2}>
        {supplyDate}
      </Content>
      <Content active={refundHistoryStatus !== 'CANCEL'} flex={1.5}>
        {nation}
      </Content>
      <Content active={refundHistoryStatus !== 'CANCEL'} flex={3.5}>
        {touristName}
      </Content>
      {isAutoPayment ? (
        <>
          <Content active={refundHistoryStatus !== 'CANCEL'} flex={3}>
            {getComma(totalPrice)} 원
          </Content>
          <Content active={refundHistoryStatus !== 'CANCEL'} flex={2}>
            {getComma(refundPrice)} 원
          </Content>
          <Content active={refundHistoryStatus !== 'CANCEL'} flex={3}>
            {getComma(
              paymentType === 'ORIGINAL'
                ? +totalPrice - +refundPrice
                : +totalPrice,
            )}
            원
          </Content>
        </>
      ) : (
        <>
          <Content active={refundHistoryStatus !== 'CANCEL'} flex={3}>
            {getComma(totalPrice)} 원
          </Content>
          <Content active={refundHistoryStatus !== 'CANCEL'} flex={2}>
            {getComma(refundPrice)} 원
          </Content>
          {isKioskStore && showKioskPrice && (
            <Content
              active={refundHistoryStatus !== 'CANCEL'}
              flex={3}
              isKioskStore
            >
              {getComma(extraRefund)} 원
            </Content>
          )}
        </>
      )}
      <Content flex={2.2}>
        <Button
          text='보기'
          padding='5px 10px'
          color='#246CF6'
          borderRadius='4px'
          fontWeight
          onClick={() => setDetailModal({ data: refund, isActive: true })}
        />
      </Content>
      <Content flex={1.5}>
        {refundHistoryStatus === 'CANCEL' ||
        isTransfer ||
        (canCancel && !canCancel) ? (
          <></>
        ) : (
          <DeleteBtn onClick={onToggleDeleteModal}>
            <DeleteImg src={DeleteIcon} />
          </DeleteBtn>
        )}
      </Content>
      {isModal.isActive && (
        <DetailModal refund={refund} setIsModal={setIsModal} />
      )}
      {detailModal.isActive && (
        <DetailModal
          refund={detailModal.data}
          setUploadModal={setDetailModal}
        />
      )}
      {isDeleteModal && (
        <CustomModal
          title='환급 취소'
          desc='환급을 취소하시겠습니까?'
          btnTitle='환급 취소'
          subBtnTitle='닫기'
          onClickSubBtn={onToggleDeleteModal}
          onClickBtn={onClickRefundCancle}
          contentElement={
            <ContentContainer dir='column'>
              시술일자 {supplyDate}
              <br />
              국적 {nation}
              <br />
              결제 금액{' '}
              {isAutoPayment
                ? getComma(
                    paymentType === 'ORIGINAL'
                      ? +totalPrice - +refundPrice
                      : +totalPrice,
                  )
                : getComma(totalPrice)}
              원
              <br />
              환급액 {getComma(refundPrice)}원
            </ContentContainer>
          }
        />
      )}
    </Item>
  );
}

const Item = styled.li`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.mono[10]};
  :last-child {
    border-bottom: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  background-color: ${(props) => props.theme.pointColors.white};
  :hover {
    background-color: #e9f0fe;
  }
`;

const Content = styled.div<{
  flex: number;
  active?: boolean;
  isKioskStore?: boolean;
}>`
  color: ${(props) =>
    props.isKioskStore && props.active
      ? props.theme.blue[200]
      : props.active
      ? props.theme.mono[80]
      : props.theme.mono[35]};
  position: relative;
  display: flex;
  flex: ${(props) => props.flex};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  @media ${(props) => props.theme.mediumView} {
    padding: 12px 20px;
  }
`;
const DeleteImg = styled.img`
  width: 18px;
  height: 18px;
`;
const DeleteBtn = styled.button``;
const ContentContainer = styled(FlexWrap)`
  background-color: ${(props) => props.theme.mono[5]};
  border-radius: 4px;
  padding: 12px;
  color: ${(props) => props.theme.mono[65]};
`;
export default RefundDetailItem;
