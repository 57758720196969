import {
	IPassportValidatePayload,
	IPassportValidateResponse,
} from '@/types/refund';
import api from '..';

// 여권 유효성검사
export const passportValidate = async (payload: IPassportValidatePayload) => {
	const res = await api.postAxios<
		IPassportValidateResponse,
		IPassportValidatePayload,
		null
	>('/user/tourist/validate', payload);
	return res.data;
};
// 강제등록
export const forceRegiser = async (payload: IPassportValidatePayload) => {
	const res = await api.postAxios<
		IPassportValidateResponse,
		IPassportValidatePayload,
		null
	>('/user/tourist/force/register', payload);
	return res.data;
};
