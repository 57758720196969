import { AxiosRequestConfig } from 'axios';

export const SERVER_PROD = 'https://elb-medical.ktaxpay.com';
export const SERVER_TEST = 'https://elb-medicaltest.ktaxpay.com';
export const KIOSK_PROD = 'https://kiosk.ktaxpay.com';
export const KIOSK_SERVER_TEST = 'http://123.142.246.2:50927';

const currentUrl: string = window.location.href;

const getMedicalServerUrl = (): string => {
	const isKiosk = currentUrl.includes('kiosk');

	const isProduction =
		process.env.NODE_ENV === 'production' && !currentUrl.includes('test');
	if (isKiosk) {
		return isProduction ? KIOSK_PROD : KIOSK_SERVER_TEST;
		// 추후 'return isProduction ? KIOSK_PROD : KIOSK_SERVER_TEST;' 변경 (창우님과 이야기후)
	} else {
		return isProduction ? SERVER_PROD : SERVER_TEST;
	}
};

const MEDICAL_SERVER: string = getMedicalServerUrl();

export const axiosRequestConfiguration: AxiosRequestConfig = {
	baseURL: MEDICAL_SERVER,
	responseType: 'json',
	withCredentials: false,
};
