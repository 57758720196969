import { SupplyHeader } from '@/constants/supply';
import styled from 'styled-components';
import SupplyInput from './SupplyInput';
import { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isPorteKor?: boolean;
};

function SupplyDetail({ setIsOpen, isPorteKor = false }: Props) {
  // const methods = useFormContext();

  // const resetRefund = () => {
  // 	const supplyList = methods.getValues('supply') || [];
  // 	supplyList.map((supply: any, index: number) => {
  // 		methods.setValue(`supply.${index}.price`, '');
  // 		methods.setValue(`supply.${index}.productType`, '');
  // 		methods.setValue(`supply.${index}.supplyPrice`, '');
  // 		methods.setValue(`supply.${index}.vatPrice`, '');
  // 	});
  // 	methods.setValue('porteRefund', []);
  // };

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title>용역 공급 내역</Title>
        {/* <Button
					onClick={() => {
						resetRefund();
						setIsOpen(true);
					}}
				>
					다시 입력
				</Button> */}
      </div>
      <SupplyContainer>
        <Header>
          {SupplyHeader.map((item) => (
            <HeaderTitle
              key={item.id}
              flex={item.flex}
              highlight={item.highlight || false}
            >
              {item.title}
            </HeaderTitle>
          ))}
        </Header>
        <SupplyInput isPorteKor={isPorteKor} />
      </SupplyContainer>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
`;

const SupplyContainer = styled.div`
  margin-top: 16px;
  background-color: ${(props) => props.theme.pointColors.white};
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.mono[20]};
`;

const HeaderTitle = styled.div<{ flex: number; highlight?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 12px 0;
  flex: ${(props) => props.flex};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
  color: ${(props) =>
    props.highlight ? props.theme.mono[65] : props.theme.mono[35]};
`;

const Button = styled.button`
  display: flex;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: white;
  background-color: #246cf6;
  :hover {
    opacity: 0.7;
  }
`;

export default SupplyDetail;
