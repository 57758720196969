import { AxiosError } from 'axios';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import uploadSuccessImage from '@/assets/refund/check_circle.png';
import Button from '@/components/common/Button';
import FlexWrap from '@/components/common/FlexWrap';
import ExcelTable from '@/components/refund/ExcelTable';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { convertComponentToImage } from '@/util/common';
import { uploadExel } from '@/api/user';
import { IUploadExcelImagePayload } from '@/types/user';

function RefundComplete() {
  const { touristIndex, refundIndex } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const excelRef = useRef<MutableRefObject<HTMLDivElement | null>>(null);

  const uploadExcelImage = async () => {
    if (excelRef) {
      const blob = await convertComponentToImage(excelRef.current as any);
      const formData = new FormData();
      formData.append('file', blob);
      formData.append('refundId', refundIndex[0] || refundIndex);
      uploadMutation.mutate({ formData });
    }
  };
  const uploadMutation = useMutation<
    number,
    AxiosError,
    IUploadExcelImagePayload
  >((payload) => uploadExel(payload), {
    retry: false,
  });

  useEffect(() => {
    const timer = setTimeout(uploadExcelImage, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container>
      <Icon src={uploadSuccessImage} />
      <Title>환급 신청 완료</Title>
      <Content>환급을 신청했습니다.</Content>
      <FlexWrap gap='30px'>
        <Button
          text='홈으로'
          width='220px'
          height='52px'
          color='#3A3B3E'
          backgroundColor='#E5E6E8'
          borderRadius='8px'
          onClick={() => navigate('/')}
          fontWeight
        />
        <Button
          text='환급 내역 보기'
          width='220px'
          height='52px'
          color='#FFF'
          backgroundColor='#246CF6'
          borderRadius='8px'
          onClick={() => navigate('/refund-detail')}
          fontWeight
        />
      </FlexWrap>
      <Hidden>
        <ExcelContainer ref={excelRef}>
          <ExcelTable
            refundIndex={+refundIndex[0] || +refundIndex}
            touristIndex={+touristIndex}
          />
        </ExcelContainer>
      </Hidden>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 40px 85px 40px;
  min-height: calc(100vh - 80px);
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

const Icon = styled.img`
  width: 80px;
  height: 80px;
`;

const Title = styled.h1`
  margin: 32px 0 8px;
  white-space: pre-wrap;
  text-align: center;
  position: relative;
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
`;

const Content = styled.p`
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: 32px;
  color: ${(props) => props.theme.mono[65]};
`;

const Hidden = styled.div`
  display: none;
`;
const ExcelContainer = styled.div<{ ref: any }>`
  width: 1200px;
  position: absolute;
  top: -2500px;
  left: 400px;
`;
export default RefundComplete;
