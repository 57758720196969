import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { logOut } from '@/api/user';
import FlexWrap from '@/components/common/FlexWrap';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { loginUserState } from '@/store/modules/user';

type Props = {
  onClickChangePassword: () => void;
};
function MedicalBottomSection({ onClickChangePassword }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { refetch } = useQuery<null, AxiosError>('logOut', logOut, {
    enabled: false,
    retry: false,
    onSuccess: () => {
      localStorage.setItem('loginToken', '');
      dispatch(loginUserState(false));
      navigate('/');
    },
    onError: (error) => {
      console.log('에러', error);
    },
  });
  const onLogOut = () => {
    refetch();
    window.localStorage.removeItem('name');
    window.localStorage.removeItem('profileImg');
  };

  return (
    <Container justifyContent='space-between' alignItems='flex-end'>
      정보 수정이 필요한 경우 KTP 상담센터(02-6275-8011)로 연락해주세요.
      <FlexWrap gap='28px'>
        <Button onClick={onLogOut}>로그아웃</Button>
        <Button onClick={onClickChangePassword}>비밀번호 변경</Button>
      </FlexWrap>
    </Container>
  );
}

const Container = styled(FlexWrap)`
  width: 100%;
  margin: 120px 0 32px;
  font-size: 16px;
  color: ${(props) => props.theme.mono[65]};
`;
const Button = styled.button`
  padding: 12px;
  width: 240px;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.mono[80]};
  background-color: ${(props) => props.theme.mono[10]};
  border-radius: 8px;
  :hover {
    opacity: 0.6;
  }
`;
export default MedicalBottomSection;
